<template>
  <div class="card text-white m-2 px-2 text-center flex-grow-1" :class="color">
    <div class="card-header">{{ title }}</div>
    <div class="card-body">
      <h5 class="card-title">
        <slot name="cardTitle"></slot>
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardBoxData",
  props: {
    title: String,
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
